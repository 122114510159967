.navbar-container {
    max-width: 800px; /* Match the max-width of #newsItemContainer */
    margin: 0 auto; /* Center the container */
    /* padding: 0 20px; /* Optional: add padding for better alignment */
} 

#navbar {
    background-color: #202020;
    width: 100%;
}

#navbarLogo {
    padding-top: 10px;
}

:root {
    --site-font: 'Libre Baskerville', serif;
    background-color: #202020;
    overscroll-behavior: none; /* Disable overscroll behavior */
  }
  
  #root
  {
    background-color: #202020;
  }

#navbarHeading {
    font-family: var(--site-font);
    padding: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}



#navbarButtonSpacer {
    padding-top: 10px;
}

#navDropdown {
    background-color: #8989896c;
    border-radius: 20px;
    border: 0px;

    font-family: var(--site-font);
}

#navMenu {
    background-color: #202020;
    text-align: center; /* Center align the text */
}

#navMenuItem {
    color: white !important;
    display: block;
    text-align: center; /* Center align each item */
    font-family: var(--site-font);
}

#navMenuItem:hover {
    background-color: #343a40 !important;
    color: white !important;
}

#spacer
{
    height: 40px;

}