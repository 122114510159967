.home {
    padding: 20px;
    text-align: center;
    overscroll-behavior: none; /* Disable overscroll behavior */
    
    @media (min-width: 768px) {
      padding: 40px;
    }
  }
  
#homeModal
  {
    background-color: #202020;
    color: white;
    border-color: transparent;
    overscroll-behavior: none; /* Disable overscroll behavior */
  }