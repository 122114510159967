@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&display=swap');

:root {
    --site-font: 'Libre Baskerville', serif;
    background-color: #202020;
    overscroll-behavior: none; /* Disable overscroll behavior */
}

#root
{
    background-color: #202020;
}

body {
    font-family: var(--site-font);
    
}

#documentButton, #articleButton, #addToCollectionButton {
    padding: 5px 10px;
    margin: 5px;
    color: white;
    background-color: #8989896c;
    border-radius: 20px;
    border: 0;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
    font-family: var(--site-font);
    font-size: 14px;
}

#newsItemContainer {
    padding: 20px;
    border-radius: 20px;
    margin: 20px auto;
    background-color: #9a9a9a2e;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192);
    max-width: 800px; /* Add a max width to center the container */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Ensure items are aligned to the start */
}

#feedSpacing {
    padding: 10px;
}

#backGround {
    background-color: #202020;
}

#titleText {
    font-size: 18px;
    font-family: var(--site-font);
    color: rgb(249, 245, 245);
    margin-bottom: 10px; /* Space below the title */
    text-align: left;
}

#feedBodyText {
    font-size: 12px;
    font-family: var(--site-font);
    padding-top: 0px;
    padding-bottom: 10px;
    color: rgb(249, 245, 245);
    display: flex;

}

#feedBodyText > div {
    margin-right: 10px; /* Add more space between the div elements */
}

#buttonContainer {
    display: flex;
    flex-wrap: wrap; /* Ensure buttons wrap if necessary */
    gap: 10px; /* Space between buttons */
    align-items: center; /* Align buttons vertically */
}


#search {
    color: #8989896c;
    background-color: #202020;
    padding: 10px 15px;
    color: white;
    width: 100%;
    box-sizing: border-box; /* Ensure padding is included in the element's width */
    display: flex; /* Use flex to control the layout */
    justify-content: center; /* Center the content */
}

#searchInput {
    background-color: #8989896c;
    border-color: transparent;
    border-radius: 10px;
    color: white;
    width: 100%;
    max-width: 800px; /* Limit the width to be slightly larger than the news item container */
}

#searchButton
{
    background-color: #8989896c;
    border-radius: 20px;
    border: 0;
    font-family: var(--site-font);
    margin-left: 10px; /* Add space between the input and the button */
    padding: 0 15px; /* Adjust padding to fit the button */
}




#standardButton {
    background-color: #8989896c;
    border-radius: 20px;
    border: 0;
    margin-right: 5px;
    margin-left: 10px;
    font-family: var(--site-font);

}

#newsModal {
    padding-top: 50px;
}

#newsModalHeader {
    background-color: #202020;
    color: white;
    border-color: #1f1b1b;
}

#newsModalBody {
    background-color: #202020;
    color: white;
}

#noteModalForm {
    position: relative;
    padding: 20px;
}

#noteModalNewsItem {
    width: calc(98%);
    background-color: #8989896c;
    color: white;
    border-color: transparent;
    
}

.form-control::placeholder {
    color: white !important;
}

#noteModalNewsItemFooter {
    background-color: #202020;
    border-color: transparent;
}

#noteSaveToCollectionButton {
    background-color: #8989896c;
    color: white;
    border-color: transparent;
    box-shadow: transparent;
    width: 200px;
    border-radius: 20px;
    font-family: var(--site-font);
    font-size: 14px;
}

.no-results-message {
    color: white;
    text-align: center;
    margin-top: 20px;
    background-color: #202020;
  }

  #noteModalFooter
  {
    border-color: transparent;
    background-color: #202020;
  }
  