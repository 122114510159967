.footer {
  background-color: #202020;
  color: white;
  text-align: center;
  padding: 20px 0;

  @media (max-width: 768px) {
    display: none;
  }
}

#footerNav {
  justify-content: center;
  width: 100%;
}

#footerContent {
  color: white;
}

:root {
  --site-font: 'Libre Baskerville', serif;
  background-color: #202020;
  overscroll-behavior: none; /* Disable overscroll behavior */
}

#root {
  background-color: #202020;
}

#standardButton {
  display: inline-flex;
  align-items: center;
  color: white;

  // &:hover {
  //   color: #ff6347; // Optional: Change hover color if desired
  // }
}

#donateIcon {
  padding-right: 2px;
  color: red;
}

.nav-link {
  color: white;
  padding: 0 10px; // Adjust padding to space out items in a row
}

.nav-link:hover {
  color: #ff6347; // Optional: Change hover color if desired
}
